<template>
  <div class="view-session-start">
    <div class="view-header">
      <h2>Nouvelle session</h2>
    </div>
    <form>
      <div class="block">
        <label>Projet</label>
        <em>Selectionner le projet</em>
        <select v-model="project">
          <option v-for="p in projects"
            :key="p.id" :value="p">{{p.name}}</option>
        </select>
        <div class="radio-list">
          <label>
            <input type="checkbox" :value="true" v-model="sandbox" />
            Utiliser la sandbox
          </label>
        </div>
      </div>
      <div class="block" v-if="project">
        <dl>
          <dt>Url</dt>
          <dd>{{project.url}}</dd>
          <dt>Sitemap</dt>
          <dd>{{project.sitemap}}</dd>
        </dl>
      </div>
      <div class="block" v-if="project">
        <label>Appareils</label>
        <em>Certains sites ont des caches différents pour les accès mobile</em>
        <div class="radio-list">
          <label>
            <input type="checkbox"
              v-model="project.userSettings.agents" value="desktop" />
            <agent agent="desktop" />
          </label>
          <label>
            <input type="checkbox"
              v-model="project.userSettings.agents" value="mobile" />
            <agent agent="mobile" />
          </label>
          <label>
            <input type="checkbox"
              v-model="project.userSettings.agents" value="bot" />
            <agent agent="bot" />
          </label>
          <label>
            <input type="checkbox"
              v-model="project.userSettings.agents" value="bot:mobile" />
            <agent agent="bot:mobile" />
          </label>
        </div>
      </div>
      <div class="block" v-if="project">
        <label>Courbe de chauffe</label>
        <em>
            Vitesse de montée en température.
            Si votre site répond vite à notre solicitation,
            avec un mode aggressif nous augmenterons beaucoup plus rapidement la vitesse de chauffe.
            <br>Quoiqu'il arrive vous serez protégé par
           notre système de protection <b>SafeWarm</b>, qui va s'assurer que votre site
            continue de répondre correctement pendant la phase de préchauffage<br>
            En mode aggressif cependant vous pourrez constater des lenteurs.
        </em>
        <div class="radio-list">
          <label>
            <input type="radio" name="warmingCurveMode"
              v-model="project.userSettings.warmingCurveMode"
              value="fast" />
            Aggressif
            <em>Vous avez un site avec de bons serveurs ?
              Nous aussi !</em>
          </label>
          <label>
            <input type="radio" name="warmingCurveMode"
              v-model="project.userSettings.warmingCurveMode"
              value="normal"/>
            Normal (recommandé)
            <em>Ni trop rapide, ni trop lent, le bon compromis</em>
          </label>
          <label>
            <input type="radio" name="warmingCurveMode"
              v-model="project.userSettings.warmingCurveMode"
              value="slow" />
            Doux
            <em>Votre site n'est pas d'ordinaire très véloce ?
               un serveur sous dimensionné ? on va en prendre soin ;)</em>
          </label>
        </div>
      </div>
      <div class="block" v-if="project">
        <label>Economiseur de bande passante</label>
        <em>Chez certains hébergeur la bande passante vous est facturée,
          dans le cas de site importants, celà peut entrainer une surfacturation.<br>
          Nous ? on essaye met déjà tout en place pour que ça vous coûte le moins cher possible,
            mais on peut toujours faire mieux !</em>
        <div class="radio-list">
          <label>
            <input type="radio" name="bandwithSaverMode"
              v-model="project.userSettings.bandwithSaverMode" value="off" />
            Inactif
          </label>
          <label>
            <input type="radio" name="bandwithSaverMode"
              v-model="project.userSettings.bandwithSaverMode" value="active" />
            Actif
            <em>En activant cette option, nous allons parfois
              lancer les requetes sur votre site sans en attendre la réponse.<br>
            Cela fonctionne plutôt bien pour des cache au niveau de votre site,
            cependant si vous utiliser un CDN externe,
            nous vous déconseillons d'activer cette option.
            </em>
          </label>

        </div>
      </div>

      <div class="control-bar">
        <button class="primary" v-if="project" @click.prevent="start">Lancer !</button>
      </div>
    </form>
  </div>
</template>
<script>
import { mapActions, mapState } from 'vuex';
import Agent from '../components/Agent.vue';

export default {
  components: { Agent },
  data() {
    return {
      project: null,
      sandbox: false,
      // agents: [],
      // bandwithSaverMode: null,
      // warmingCurveMode: null,
    };
  },
  computed: {
    ...mapState('projects', [
      'projects',
    ]),
  },
  methods: {
    ...mapActions('projects', [
      'loadProjects',
    ]),
    ...mapActions('sessions', [
      'startSession',
    ]),

    async start() {
      const id = await this.startSession({
        projectId: this.project.id,
        sandbox: this.sandbox,
        agents: this.project.userSettings.agents,
        warmingCurveMode: this.project.userSettings.warmingCurveMode,
        bandwithSaverMode: this.project.userSettings.bandwithSaverMode,
      });
      this.$router.push({ name: 'Session', params: { id } });
    },
  },
  mounted() {
    this.loadProjects();
  },
};
</script>
<style lang="scss" scoped>

</style>
